import toast from "react-hot-toast";

export async function usernameHandler(values) {
  const errors = usernameValidate({}, values);
  return errors;
}
export async function passwordHandler(values) {
  const errors = passwordValidate({}, values);
  return errors;
}
export async function otpHandler(values) {
  const errors = otpValidate({}, values);
  return errors;
}
export async function registerValidator(values) {
  const errors = globalRegisterValidator({}, values);
  return errors;
}
export async function updateValidator(values) {
  const errors = globalUpdateValidator({}, values);
  return errors;
}
export async function globalLoginHandler(values) {
  const errors = globalLoginValidator({}, values);
  return errors;
}
function usernameValidate(errors = {}, values) {
  if (!values.username) {
    errors.username = toast.error("Username/Email Required");
  } else if (values.username.includes(" ")) {
    errors.username = toast.error("Invalid Username/Email");
  }
  return errors;
}

function passwordValidate(errors = {}, values) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  if (!values.password) {
    errors.password = toast.error("Password Required...!");
  } else if (values.password.length < 8) {
    errors.password = toast.error(
      "Password must be more than 8 characters long"
    );
  } else if (values.password != values.passwordConfirm) {
    errors.password = toast.error("Password Not Same");
  } else if (!specialChars.test(values.password)) {
    errors.password = toast.error("Password must have special character");
  }

  return errors;
}

/*GLOBAL PASSWORD EMAIL HANDLER */
function globalLoginValidator(errors = {}, values) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  if (!values.email) {
    errors.email = toast.error("Email Required");
  } else if (values.email.includes(" ")) {
    errors.email = toast.error("Invalid Email");
  }
  if (!values.password) {
    errors.password = toast.error("Password Required...!");
  } else if (values.password.length < 8) {
    errors.password = toast.error(
      "Password must be more than 8 characters long"
    );
  } else if (
    values.passwordConfirm &&
    values.password != values.passwordConfirm
  ) {
    errors.password = toast.error("Password Not Same");
  } else if (!specialChars.test(values.password)) {
    errors.password = toast.error("Password must have special character");
  }
  return errors;
}

function globalRegisterValidator(errors = {}, values) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  if (!values.name) {
    errors.name = toast.error("Name Required...!");
  } else if (!values.password) {
    errors.password = toast.error("Password Required...!");
  } else if (values.password.length < 8) {
    errors.password = toast.error(
      "Password must be more than 8 characters long"
    );
  } else if (
    values.passwordConfirm &&
    values.password != values.passwordConfirm
  ) {
    errors.password = toast.error("Password Not Same");
  } else if (!specialChars.test(values.password)) {
    errors.password = toast.error("Password must have special character");
  } else if (!values.email) {
    errors.email = toast.error("Email Required...!");
  } else if (values.email.includes(" ")) {
    errors.email = toast.error("Wrong Email...!");
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = toast.error("Invalid email address...!");
  }
  return errors;
}
function globalUpdateValidator(errors = {}, values) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  if (!values.username) {
    errors.username = toast.error("Username/Email Required");
  } else if (values.username.includes(" ")) {
    errors.username = toast.error("Invalid Username/Email");
  } else if (!values.address) {
    errors.address = toast.error("Address Required");
  } else if (!values.phone) {
    errors.phone = toast.error("Phone Required");
  } else if (values.username.includes(" ")) {
    errors.phone = toast.error("Invalid Phone");
  }
  return errors;
}

function otpValidate(errors = {}, values) {
  if (!values.otp) {
    errors.otp = toast.error("Please Provide OTP");
  }
  return errors;
}
