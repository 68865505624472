import axios from "axios";
import { toast } from "react-hot-toast";
const api = axios.create({
  baseURL: "https://jealous-getup-toad.cyclic.app",
  withCredentials: true,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "https://admin-dashboard-umarahmed.pages.dev",
  },
});
export const loginManager = async (values) => {
  try {
    const response = await api.post("auth/login", values, {
      withCredentials: true,
      headers: {
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "https://admin-dashboard-umarahmed.pages.dev",
      },
    });

    return response?.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      // and an instance of http.ClientRequest in node.js

      return error.request;
    } else {
      // Something happened in setting up the request that triggered an Error

      return error.message;
    }
  }
};

export const registerManager = async (values) => {
  try {
    const response = await api.post("/auth/register", values, {
      withCredentials: true,
      headers: {
        "Content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "https://admin-dashboard-umarahmed.pages.dev",
      },
    });

    return response?.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      // and an instance of http.ClientRequest in node.js

      return error.request;
    } else {
      // Something happened in setting up the request that triggered an Error

      return error.message;
    }
  }
};

export const updateManager = async (values) => {
  try {
    const response = await api.patch("user/update", values, {
      withCredentials: true,
      headers: {
        "Content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "https://admin-dashboard-umarahmed.pages.dev",
      },
    });

    return response?.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      // and an instance of http.ClientRequest in node.js

      return error.request;
    } else {
      // Something happened in setting up the request that triggered an Error

      return error.message;
    }
  }
};

export const sendOtpManager = async (values) => {
  try {
    const response = await api.get("/auth/verify", values, {
      withCredentials: true,
      headers: {
        "Content-type": "multipart/form-data",
        "Access-Control-Allow-Origin": "https://admin-dashboard-umarahmed.pages.dev",
      },
    });
    if (response?.data.code === 202)
      toast.success(`${response?.data.message}`, { duration: 1500 });
    return;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response)
      toast.error(`${error.response.data.message}`, { duration: 1500 });
      return error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      // and an instance of http.ClientRequest in node.js
      toast.error(`No Network Connection`, { duration: 1500 });
      return error.request;
    } else {
      // Something happened in setting up the request that triggered an Error
      toast.error(`Unknown Error Occured`, { duration: 1500 });
      return error.message;
    }
  }
};

export const verifyOtpManager = async (values) => {
  try {
    const response = await api.post("/auth/verify", values, {
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": "https://admin-dashboard-umarahmed.pages.dev",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      },
    });
    if (response?.data) return response?.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      // console.log(error.response)

      return error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser
      // and an instance of http.ClientRequest in node.js

      return error.request;
    } else {
      // Something happened in setting up the request that triggered an Error

      return error.message;
    }
  }
};
