import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
function Protected({ children }) {
  const navigator = useNavigate();
  const [code, setCode] = useState(0);
  useEffect(() => {
    axios
      .get(
        "https://jealous-getup-toad.cyclic.app/auth/checksession",
        { withCredentials: true },
        {
          headers: {
            "Content-type": "application/json",
            "Access-Control-Allow-Credentials": "true",
            "Access-Control-Allow-Origin":
              "https://admin-dashboard-umarahmed.pages.dev",
          },
        }
      )
      .then((res) => {
        setCode(res?.status);
      })
      .catch((err) => {
        const data = err?.response;
        console.log(data?.data?.error);
        setCode(data?.data?.error);
      });
  }, []);

  if (code === 401) navigator("/");
  if (code === 200) return children;
}
export default Protected;
