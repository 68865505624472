import LoginPage from "./components/LoginPage";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Protected from "./Protected";
import ResetPage from "./components/ResetPage";
import RegisterPage from "./components/RegisterPage";
import UpdatePage from "./components/UpdatePage";
import VerifyEmail from "./components/VerifyEmail";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/reset"
          element={
            <Protected>
              <ResetPage />
            </Protected>
          }
        />
        <Route
          path="/verify"
          element={
            <Protected>
              <VerifyEmail />
            </Protected>
          }
        />
        <Route path="/" exact element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route
          path="/updateme"
          element={
            <Protected>
              <UpdatePage />
            </Protected>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
export default App;
{
  /* <Route path="/" element={<protectedRoutes Component={LoginPage} />} /> */
}
