import React, { useState } from "react";
import { useFormik } from "formik";
import { Toaster } from "react-hot-toast";
import { toast } from "react-hot-toast";
import { useCheckLoginQuery } from "../api/apiSlice";
import { otpHandler } from "../helpers/validate";
import { sendOtpManager, verifyOtpManager } from "../api/login";
const VerifyEmail = () => {
  const [disabled, setDisabled] = useState(false);
  const [seconds, setSeconds] = useState(60);
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validate: otpHandler,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      const data = await verifyOtpManager(values);
      if (data.error) toast.error(`${data.message}`, { duration: 1500 });
      if (data.code === 202) {
        toast.success(`${data.message}`, { duration: 1500 });
      }
    },
  });
  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900">
        <Toaster position="top-center" reverseOrder={false}></Toaster>
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a
            href="#"
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <img
              className="w-8 h-8 mr-2"
              src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg"
              alt="logo"
            />
            Flowbite
          </a>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Verify Your Account
              </h1>
              <form
                className="space-y-4 md:space-y-6"
                action="#"
                onSubmit={formik.handleSubmit}
              >
                <div>
                  <label
                    htmlFor="otp"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Enter The OTP
                  </label>
                  <input
                    {...formik.getFieldProps("otp")}
                    type="password"
                    name="otp"
                    id="otp"
                    placeholder="••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                  />
                </div>
                <button
                  onClick={() => {
                    var timer = setInterval(() => {
                      setSeconds((prev) => prev - 1);
                    }, 1000);
                    sendOtpManager();
                    setDisabled((prev) => !prev);
                    setTimeout(() => {
                      setDisabled((prev) => !prev);
                      clearInterval(timer);
                    }, 60000);
                  }}
                  type="button"
                  disabled={disabled}
                  className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 disabled:opacity-25 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Send OTP
                </button>
                <button
                  type="submit"
                  className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Verify
                </button>
                <p
                  className={`text-sm font-light text-gray-500 dark:text-gray-400 ${
                    disabled ? "" : "hidden"
                  } `}
                >
                  Please Wait <b>00:{seconds}</b> Before Trying Again
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default VerifyEmail;
