import React, { useState } from "react";
import { useFormik } from "formik";
import { toast, Toaster } from "react-hot-toast";
import { updateValidator } from "../helpers/validate";
import avatar from "../images/avatar.png";
import { useCheckLoginQuery } from "../api/apiSlice";
import { useNavigate } from "react-router-dom";
import { updateManager } from "../api/login";
const UpdatePage = () => {
  const [preview, setPreview] = useState("");
  const onUpload = async (e) => {
    formik.setFieldValue("photo", e.currentTarget.files[0]);
    console.log(e.currentTarget.files[0]);
    const data = new FileReader();
    data.addEventListener("load", () => {
      setPreview(data.result);
    });
    data.readAsDataURL(e.currentTarget.files[0]);
  };
  const formik = useFormik({
    initialValues: {
      photo: null,
      phone: "",
      username: "",
      address: "",
    },
    validate: updateValidator,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      let formData = new FormData();
      formData.append("phone", values.phone);
      formData.append("username", values.username);
      formData.append("address", values.address);
      formData.append("photo", values.photo);
      const data = await updateManager(formData);
      if (data.message === "Success") {
        toast.success("Done", { duration: 1500 });
      }
      console.log(data)
    },
  });

  return (
    <section className="bg-gray-50 dark:bg-gray-900 ">
      <Toaster position="top-center" reverseOrder={false}></Toaster>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <a
          href="#"
          className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
        >
          <img
            className="w-8 h-8 mr-2"
            src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/logo.svg"
            alt="logo"
          />
          Flowbite
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              Update Profile
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              action="#"
              onSubmit={formik.handleSubmit}
            >
              <div>
                <div className="profile flex justify-center py-4">
                  <label htmlFor="profile">
                    <img
                      src={`${preview}` || avatar}
                      alt="profile"
                      className=" border-4 border-gray-100 min-w-[135px] min-h-[135px] max-w-[135px] max-h-[135px] block object-cover rounded-[50%] shadow-lg cursor-pointer hover:border-gray-200"
                    />
                  </label>
                  <input
                    type="file"
                    id="profile"
                    name="profile"
                    onChange={onUpload}
                    style={{ display: "none" }}
                  />
                </div>

                <label
                  htmlFor="username"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your Username
                </label>
                <input
                  {...formik.getFieldProps("username")}
                  type="username"
                  name="username"
                  id="username"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                />
                <label
                  htmlFor="phone"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your Phone
                </label>
                <input
                  {...formik.getFieldProps("phone")}
                  type="phone"
                  name="phone"
                  id="phone"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                />
                <label
                  htmlFor="address"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Your Address
                </label>
                <input
                  {...formik.getFieldProps("address")}
                  type="address"
                  name="address"
                  id="address"
                  className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder=""
                  required=""
                />
              </div>
              <button
                type="submit"
                className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Update
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpdatePage;
